<template>
  <div>
    <!--  Snackbar  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          shaped
          top
          v-model="snackbarStatus"
          :timeout="snackbarTimeout"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <h3>پلن های کاربری</h3>

    <v-divider class="mt-4 mb-4"></v-divider>

    <v-row>
      <!--   Plans   -->
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="3" v-for="(plan, index) in plans.data" :key="index">
        <v-card style="border-radius: 25px" elevation="10">
          <v-card-title class="mb-10 white--text d-flex justify-center" style="background-color: #161853;">
            <h4>{{ plan.title }}</h4>
          </v-card-title>
          <v-card-subtitle>
            <p>{{ plan.description }}</p>
          </v-card-subtitle>
          <div style="">
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-card shaped style="border-radius: 25px 0 25px 0; background: rgb(171,0,0)">
                    <v-card-title>
                      <div style="width: 100%; justify-content: center; display: flex;">
                        <h6 class="white--text">تصویر</h6>
                      </div>
                      <v-icon x-large color="white" style="width: 100%">mdi-image</v-icon>
                      <div style="width: 100%; justify-content: center; display: flex">
                        <span v-if="plan.count !== null" class="white--text">{{ plan.count }}</span>
                        <span v-else class="white--text" style="font-size: 15px">نامحدود</span>
                      </div>
                    </v-card-title>

                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-card shaped style="border-radius: 25px 0 25px 0; background: rgb(0,38,139)">
                    <v-card-title>
                      <div style="width: 100%; justify-content: center; display: flex;">
                        <h6 class="white--text">ویدیو</h6>
                      </div>
                      <v-icon x-large color="white" style="width: 100%">mdi-file-video</v-icon>
                      <div style="width: 100%; justify-content: center; display: flex">
                        <span v-if="plan.minutes !== null" class="white--text">{{ plan.minutes }} <span style="font-size: 10px">دقیقه</span></span>
                        <span v-else class="white--text" style="font-size: 15px">نامحدود</span>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-card shaped style="border-radius: 25px 0 25px 0; background: rgb(142,118,0)">
                    <v-card-title>
                      <div style="width: 100%; justify-content: center; display: flex;">
                        <h6 class="white--text">استریم</h6>
                      </div>
                      <v-icon x-large color="white" style="width: 100%">mdi-video-wireless</v-icon>
                      <div style="width: 100%; justify-content: center; display: flex">
                        <span class="white--text" v-if="plan.stream_minutes">{{ plan.stream_minutes }} <span
                            style="font-size: 10px">دقیقه</span></span>
                        <span class="white--text" v-else style="font-size: 15px">نامحدود</span>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-card shaped style="border-radius: 25px 0 25px 0; background: rgb(37,142,0)">
                    <v-card-title>
                      <div style="width: 100%; justify-content: center; display: flex;">
                        <h6 class="white--text">اعتبار</h6>
                      </div>
                      <v-icon x-large color="white" style="width: 100%">mdi-calendar-today</v-icon>
                      <div style="width: 100%; justify-content: center; display: flex">
                        <span class="white--text" v-if="plan.expire">{{ plan.expire }} <span
                            style="font-size: 10px">روز</span></span>
                        <span class="white--text" v-else style="font-size: 15px">نامحدود</span>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="openDialog(plan)" rounded :color="'#161853'" class="white--text" width="100%">
                <h3 class="ml-1">خرید {{ plan.price }}</h3><span style="font-size: 10px">(ریال)</span>
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>

      <!--   Pagination   -->
      <v-col cols="12">
        <v-pagination
            color="#161853"
            v-model="plans.current_page"
            :length="plans.last_page"
            circle
            @input="getPlans"
        ></v-pagination>
      </v-col>

      <!--   Dialog   -->
      <v-dialog
          v-if="selectedPlan"
          v-model="dialog"
          width="500"
      >
        <v-card>
          <v-card-title>
            خرید پلن {{ selectedPlan.title }}
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-text-field
                  v-model="discountCode"
                  label="کد تخفیف"
              ></v-text-field>
              <v-btn class="mt-3" rounded color="success" :disabled="!discountCode" @click="checkDiscountCode">بررسی
                کد
              </v-btn>
            </v-row>

            <v-row>
              قیمت:
              <p style="width: 100%" class="ml-1" v-if="discountPrice">قیمت با محسابه تخفیف {{ discountPrice }} <span
                  style="font-size: 15px">(ریال)</span></p>
              <p style="width: 100%" class="ml-1" v-if="discountPercent">درصد تخفیف {{ discountPercent }}</p>
              <p style="width: 100%" class="ml-1">{{ selectedPlan.price }} <span style="font-size: 15px">(ریال)</span>
              </p>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="#292C6D"
                text
                @click="buyPlan"
            >
              خرید
            </v-btn>
            <v-btn
                color="#EC255A"
                text
                @click="dialog = false"
            >
              انصراف
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>
  </div>
</template>

<script>
export default {
  name: "Plans",

  data() {
    return {
      discountPrice: null,
      discountPercent: null,
      dialog: false,
      plans: [],
      selectedPlan: null,
      discountCode: null,
    }
  },

  methods: {
    getPlans() {
      let page = this.plans.current_page ?? 1
      window.axios
          .get('/user/plans?page=' + page)
          .then((res) => {
            this.plans = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
    },

    checkDiscountCode() {
      window.axios
          .post('/user/check-discount-code', {
            code: this.discountCode,
            price: this.selectedPlan.price
          })
          .then((res) => {
            this.discountPercent = res.data.data.percent
            this.discountPrice = res.data.data.amount
          })
          .catch((err) => {
            if (err.response.status === 422) {
              this.showValidationErrors(err.response.data.errors)
            } else if (err.response.status === 400) {
              this.openSnackbar(err.response.data.message, '#EC255A')
            }
          })
    },

    openDialog(plan) {
      this.dialog = true;
      this.selectedPlan = plan
      this.discountPrice = null
      this.discountPercent = null
    },

    buyPlan() {
      this.dialog = false
      window.axios.post('/user/orders/create', {
        plan_id: this.selectedPlan.id,
        discount_code: this.discountCode
      })
          .then(() => {
            this.openSnackbar('سفارش شما با موفقیت ثبت شد', 'success')
          })
          .catch((err) => {
            if (err.response.status === 422) {
              this.showValidationErrors(err.response.data.errors)
            } else if (err.response.status === 400) {
              this.openSnackbar(err.response.data.message, '#EC255A')
            }
          })
    }
  },

  mounted() {
    this.getPlans()
  }
}
</script>

<style scoped>
p {
  font-size: 20px;
}
</style>